@import '../../variables.sass'

.mindmap
  position: relative
  img
    width: 100%
  .relation-field
    position: absolute
    resize: none
    background-color: transparent
    border: none
    text-align: center
    font-family: 'diamondgirlmedium' ,sans-serif
    &:focus
      outline: none !important
  #field0
    top: 3%
    left: 19%
    width: 34% !important
    height: 13% !important
  #field1
    top: 5.5%
    right: 3%
    width: 30% !important
    height: 12% !important
  #field2
    top: 25%
    left: 28%
    width: 24% !important
    height: 7.5% !important
  #field3
    top: 26.5%
    right: 10%
    width: 25% !important
    height: 8% !important
  #field4
    bottom: 48%
    left: 40%
    width: 30% !important
    height: 8% !important
  #field5
    bottom: 26%
    left: 27%
    width: 25% !important
    height: 8% !important
  #field6
    bottom: 29%
    right: 17%
    width: 23% !important
    height: 8% !important
  #field7
    bottom: 3%
    left: 18%
    width: 34% !important
    height: 13% !important
  #field8
    bottom: 8%
    right: 4%
    width: 30% !important
    height: 12% !important
