@import '../../variables.sass'

.listen-to-text
  padding: 0 columns(2)
  display: flex
  align-items: center
  margin: 2rem 0
  button
    background: transparent
    border: 0
    align-items: center
    display: flex
    font-family: BradyBunch
    font-size: 1.5rem
    svg
      margin-right: 0.5rem
      max-width: 90px
