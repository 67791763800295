@import '../../variables.sass'

.collapse-button
  display: block
.collapse-wrapper
  padding: 0 columns(2)
  margin-bottom: 2rem
  .button-container
    display: flex
    align-items: center
    justify-content: center
  svg
    max-width: 130px
  .listen-btn
    background-color: #BE1E2D
    outline: none
    border: none
  .collapse-button
    background-color: #1C75BC
    outline: none
    border: none
  .collapse-button,
  .listen-btn
    color: white
    padding: 0.5rem 1rem
    display: flex
    align-items: center
    border-radius: 30px
    svg
      margin-right: 0.25rem
  .collapse-content
    p
      margin: 1rem 0
      font-family: 'diamondgirlskinny',sans-serif
      line-height: 1.4
  align-items: center
  p
    margin: 0 1rem

.collapse-content.collapsed
  display: none

.collapsed-content.expanded
  display: block
