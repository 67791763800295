.nav-screen
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  .content-wrapper
    align-items: center
    display: flex
    flex-direction: column
  ul
    list-style: none
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    padding: 0
    align-items: baseline
    margin: 1rem .5rem
    li
      width: 45%
      margin: 2rem 0
      img,
      svg
        width: 100%
        height: auto
