@import "variables"
@import "typography"
@import './components/navigation/Backbutton.sass'

html
  line-height: 1.15
  -ms-text-size-adjust: 100%
  -webkit-text-size-adjust: 100%
body
 margin: 0
article,
aside,
footer,
header,
nav,
section
  display: block
h1
  font-size: 2em
  margin: .67em 0
figcaption,
figure,
main
  display: block
figure
 margin: 1em 40px
hr
  box-sizing: content-box
  height: 0
  overflow: visible
pre
  font-family: monospace,monospace
  font-size: 1em
a
  background-color: transparent
  -webkit-text-decoration-skip: objects
abbr[title]
  border-bottom: none
  text-decoration: underline
  text-decoration: underline dotted
b,
strong
  font-weight: inherit
b,
strong
  font-weight: bolder
code,
kbd,
samp
  font-family: monospace,monospace
  font-size: 1em
dfn
 font-style: italic
mark
  background-color: #ff0
  color: #000
small
 font-size: 80%
sub,
sup
  font-size: 75%
  line-height: 0
  position: relative
  vertical-align: baseline
sub
 bottom: -.25em
sup
 top: -.5em
audio,
video
  display: inline-block
audio:not([controls])
  display: none
  height: 0
img
 border-style: none
svg:not(:root)
 overflow: hidden
button,
input,
optgroup,
select,
textarea
  font-family: sans-serif
  font-size: 100%
  line-height: 1.15
  margin: 0
button,
input
  overflow: visible
button,
select
  text-transform: none
[type=reset],
[type=submit],
button,
html [type=button]
  -webkit-appearance: button
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner
  border-style: none
  padding: 0
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring
  outline: 1px dotted ButtonText
fieldset
 padding: .35em .75em .625em
legend
  box-sizing: border-box
  color: inherit
  display: table
  max-width: 100%
  padding: 0
  white-space: normal
progress
  display: inline-block
  vertical-align: baseline
textarea
 overflow: auto
[type=checkbox],
[type=radio]
  box-sizing: border-box
  padding: 0
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button
  height: auto
[type=search]
  -webkit-appearance: textfield
  outline-offset: -2px
[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration
  -webkit-appearance: none
::-webkit-file-upload-button
  -webkit-appearance: button
  font: inherit
details,
menu
  display: block
summary
 display: list-item
canvas
 display: inline-block
template
 display: none
[hidden]
 display: none

html
  @include body-font-size()

@font-face
  font-family: 'BradyBunch'
  src: url('/fonts/bradybunch-webfont.woff2') format('woff2'), url('/fonts/bradybunch-webfont.woff') format('woff'), url('/fonts/BradyBunch.otf') format('opentype')
  font-weight: normal

@font-face
  font-family: 'diamondgirlskinny'
  src: url('/fonts/diamond_girl_skinny-webfont.woff2') format('woff2'), url('/fonts/diamond_girl_skinny-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'diamondgirlmedium'
  src: url('/fonts/diamond_girl-webfont.woff2') format('woff2'), url('/fonts/diamond_girl-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

body
  color: $text-color
  font-family: $default-font
  line-height: $line-height
  background-color: white

  #content-section
    flex-grow: 1

body.root
  display: flex
  flex-direction: column

  min-height: 100%
  min-height: 100vh
