@import '../../variables.sass'

.atlars
  ul
    display: flex
    flex-wrap: wrap
    padding: 0 1em
    list-style: none
    justify-content: space-between
    li
      width: 45%
  h2
    text-align: center
    margin-bottom: 1rem
  .videoWrapper
    margin-bottom: 2rem
    iframe
      width: 100%
