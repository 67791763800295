@import '../../variables.sass'

.page-intro
  display: flex
  padding: 0 columns(2)
  p
    margin-top: 0
    max-width: 50ch
  .control-wrapper
    padding-right: 1rem
