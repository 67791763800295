@import '../../variables.sass'

.atlars-track
  position: relative
  margin: 1rem 0
  .music-title
    background-color: $grey-color
    mask-size: 100% 100%
    mask-repeat: no-repeat
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    font-family: 'diamondgirlmedium' ,sans-serif
    font-weight: 500
    font-size: 1.5rem
    text-align: center
    padding: 4rem 0

    span
      display: block
  .control-wrapper
    position: absolute
    right: 0
    bottom: 0px
